<template>
  <div class="pageContol listWrap templateList templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">期次管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="期次名称" class="searchboxItem ci-full">
              <span class="itemLabel">期次名称:</span>
              <el-input
                size="small"
                v-model="periodName"
                type="text"
                placeholder="请输入期次名称"
                clearable
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="createdPeriod('add')"
              >创建期次</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="期次名称"
                align="left"
                show-overflow-tooltip
                prop="periodName"
              />
              <el-table-column
                label="培训人数"
                align="left"
                show-overflow-tooltip
                prop="trainNum"
              />
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
              >
              <template slot-scope="scope">
                {{scope.row.createTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="createdPeriod('edit',scope.row.periodId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="seeDetails(scope.row.periodId)"
                    >查看班级</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="deletePeriod(scope.row.periodId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="3%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a">
        <el-form label-width="80px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="期次名称" prop="periodName">
            <el-input
              v-model.trim="Form.periodName"
              type="text"
              size="small"
              placeholder="请输入期次名称"
            />
          </el-form-item>
          <el-form-item label="培训人数" prop="trainNum">
           <el-input-number
                  v-model="Form.trainNum"
                  @change="handleChange"
                  :min="1"
                  :max="1000000"
                  style="width:100%"
                  size="small"
                ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center;"
      >
        <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "periodManagement",
  components: {
    Empty,
    PageNum,
  },
  data() {
    return {
      periodName: "",
      dialogVisible: false, //期次弹窗
      dialogTitle: "创建期次",
      stu:'add',
      Form: {
        periodName: "",
        trainNum: "",
      },
      rules: {
        periodName: [
          { required: true, message: "请输入期次名称", trigger: "blur" },
        ],
        trainNum: [
          { required: true, message: "请输入培训人数", trigger: "blur" },
        ],
      },
    };
  },
  mixins: [List],
  created() {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if(this.periodName) {
        params.periodName = this.periodName
      }
      this.doFetch({
        url: "/biz/live/period/pageList",
        params,
        pageNum,
      },true,3);
    },
    /* 创建期次 */
    createdPeriod(stu,id='') {
      this.dialogVisible = true;
      this.stu = stu;
      this.periodId = id
      if (stu == "add") {
        this.dialogTitle = "创建期次";
      } else {
        this.dialogTitle = "修改期次";
        this.getInfo(id)
      }
    },
    doClose() {
         this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.Form={
        periodName: "",
        trainNum: "",
      }
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    doeAjax() {
      const parmar = {
        periodName: this.Form.periodName,
        trainNum: this.Form.trainNum,
      };
      if (this.periodId) {
        parmar.periodId = this.periodId;
      }
      this.$post(
        this.stu == "add"
          ? "/biz/live/period/insert"
          : "/biz/live/period/modify",
        parmar,3000, true, 3
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            // this.dialogVisible = false;
            this.doClose();
            this.getData(-1);
            // this.reset();
          }
        })
        .catch((err) => {
          return;
        });
    },
    getInfo(periodId) {
      this.$post('/biz/live/period/getInfo',{periodId},3000,true,3).then(ret => {
        this.Form = {
          ...ret.data
        }
      }).catch(err => {
        return;
      })
    },
    /* 删除 */
    deletePeriod(periodId) {
      this.doDel({
        url: "/biz/live/period/delete",
        msg: "你确定要删除该期次吗？",
        ps: {
          type: "post",
          data: { periodId },
        },
      },true,3);
    },
    /* 查看班级 */
    seeDetails(periodId) {
      this.$router.push({
        path:'/web/liveBroadcastManagement/liveClassManagementall',
        query: {
          periodId
        },
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= +35 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>

<style lang="less">
// .templateList1 {
//   // .el-cascader {
//   //   height: 32px !important;
//   // }
// }
</style>
